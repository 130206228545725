import React,{useState} from "react";
import LazyLoad from "react-lazy-load";
import FallbackDealerImg from "../../../common/common-assets/images/fallback_dealer_img.png";
import PhoneIcon from "../../../common/common-assets/icons/phoneIcon";
import IconExternalLink from "../../../common/common-assets/icons/externalLink";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";

function VehicleDisplayPageStoreInfo(props){
  const {store, vehicleSite, sendGenericActionEts, storeConfiguration} = props
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const enableMap = storeConfiguration ? storeConfiguration.enable_map : true
  console.log('vehicleSite', vehicleSite)
  
  function checkValidValue(value) {
    if (value !== undefined && value !== null && value.length > 0) {
      return value
    } else return ''
  }

  return(
    <div className="vehicle-display-page-dealer">
      <div className="vehicle-display-page-dealer-section">
        <div className="vehicle-display-page-dealer-image">
          <LazyLoad debounce={false} throttle={125} threshold={200}>
            {/* ----- TODO:: change this fallback logo image to the permanent one prior to release. ------ */}
            {vehicleSite && !imageLoadFailed  ? (
              <img
                src={store.logo_url}
                alt="Dealer Logo"
                onError={() => {
                  setImageLoadFailed(true);
                }}
              />
            ) : (
              <React.Fragment/>
            )}
          </LazyLoad>
        </div>
        <div className="vehicle-display-page-dealer-name">
          {store.full_name}
        </div>
        <div className="vehicle-display-page-dealer-address">
          {`${checkValidValue(store.street)}${checkValidValue(store.street) && checkValidValue(store.city) && checkValidValue(store.state) ? ',' : ''} ${checkValidValue(store.city)}${checkValidValue(store.city) && checkValidValue(store.state) ? ',' : ''} ${checkValidValue(store.state)} ${checkValidValue(store.zip_code)}`}
        </div>
        {vehicleSite ?
          <div
            className="vehicle-display-page-dealer-website"
          >
              <a
                onClick={()=>{
                  sendGenericActionEts("view_dealer_site_open",vehicleSite)
                }}
                href={vehicleSite} target="_blank">
                Visit Dealership Website <IconExternalLink/>
              </a>
          </div>
          :
          <React.Fragment/>
        }

        <div className="vehicle-display-page-dealer-contact">
          <div className="vehicle-display-page-dealer-contact-line">
            {
              store.phone_number &&
              <a
                onClick={()=>{
                  sendGenericActionEts("call_open", `tel:+1${store.phone_number}`)
                }}
              href={`tel:+1${store.phone_number}`}>
                <PhoneIcon />
                {formatPhoneNumber(store.phone_number)}
              </a>
            }
          </div>
          <div className="vehicle-display-page-dealer-contact-line">
            {store.email}
          </div>
        </div>
      </div>
      <div className="vehicle-display-page-dealer-section">
        {enableMap ? 
          <div className="vehicle-display-page-dealer-map">
            <iframe src={store.google_map_iframe} />
          </div> 
        : <React.Fragment/>
        }
      </div>
    </div>
  )
}
export default VehicleDisplayPageStoreInfo
