import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'
import IPacketIcon from '../../../common/common-assets/icons/iPacketIcon'
import ProfileIcon from '../../../common/common-assets/icons/profileIcon'
import SavePacketButton from '../../../common/common-components/savePacketButton'
import ArrowLeft from '../../../common/common-assets/icons/arrowLeft'
import PrintIcon from '../../../common/common-assets/icons/printicon'
import LoadingSpinner from '../../../common/common-components/loadingSpinner'

import VideoChatIcon from '../../../common/common-assets/icons/videochaticon'
import MenuIcon from '../../../common/common-assets/icons/menuIcon'
import MODAL_ACTIONS from "../../modals/state/modalActions";
import VEHICLE_DISPLAY_PAGE_ACTIONS from './state/vehicleDisplayPageActions'
import {NavLink,Link, useLocation} from 'react-router-dom'
import formatPhoneNumber from '../../../../utils/formatPhoneNumber'
import { toast } from "react-toastify";

import './vehicleDisplayPageCustomHeader.scss'

function ContactInfo(props){
  const {fullName, phoneNumber, contactImage, storeName} = props

  return(
    <div className="vehicle-display-page-header-contact-info">
      <div className="vehicle-display-page-header-contact-info-text">
        <h1>{fullName}</h1>
        <h3>{storeName}</h3>
        <h2>
          <a href={`tel:+1${phoneNumber}`}>
            {formatPhoneNumber(phoneNumber)}
          </a>
        </h2>
      </div>
      {
        contactImage?
          <div className="vehicle-display-page-header-contact-info-image-container">
            <div className="vehicle-display-page-header-contact-info-image" style={{backgroundImage: `url(${contactImage})`}}> </div>
          </div>
        :
        <React.Fragment/>
      }

    </div>
  )
}

function VehicleDisplayPageCustomHeader(props){
  const {id, storeSkin,storeConfiguration, vehicle, salespersonInfo, pushModal,sendGenericActionEts} = props
  let [mobileNavOpen, setMobileNavOpen] = useState(false)
  let [printLoading, setPrintLoading] = useState(false)
  function toggleMobileNav(){
    setMobileNavOpen(!mobileNavOpen)
  }
  //This is not the most ideal setup because of the code duplication with the original header -K
  function MobileNavDropdownToggle(props){
    return(
      <div
        className={`marketplace-header-mobile-dropdown-toggle ${mobileNavOpen?'marketplace-header-mobile-dropdown-toggle-selected':''}`}
        onClick={()=>{
          toggleMobileNav()
        }}
      >
        <MenuIcon/>
      </div>
    )
  }

  function print(){
    let pollRetrievePrint = null
    setPrintLoading(true)
    let setupPollRetrievePrint = (printId) =>{
      pollRetrievePrint = setTimeout(()=>{
        VEHICLE_DISPLAY_PAGE_ACTIONS.retrieveVdpPrint(printId).then(response=>{
          if(response.status >= 200 && response.status<300){
            return response.json()
          }
          else{
            setPrintLoading(false)
            toast('There was an issue printing your document. Please try again later')
          }
        }).then(res=>{
          if(res){
            if(res.state ==="SUCCESS" && res.s3_link){
              setPrintLoading(false)
              window.open(res.s3_link, '_blank').focus()
            }
            else if(res.state === "STARTED"){
              setupPollRetrievePrint(printId)
            }
            else{
              setPrintLoading(false)
              toast('There was an issue printing your document. Please try again later')
            }
          }
        })
      },1000)
    }
    VEHICLE_DISPLAY_PAGE_ACTIONS.initiateVdpPrint(id).then(response=>{
      if(response.status >= 200 && response.status<300){
        return response.json()
      }
      else{
        //Failure Message
        setPrintLoading(false)
        toast('There was an issue printing your document. Please try again later')
      }
    }).then(res=>{
      if(res && res.id && res.state && res.state === "STARTED"){
        setupPollRetrievePrint(res.id)
      }
      else{
        //Failure Message
        setPrintLoading(false)
        toast('There was an issue printing your document. Please try again later')
      }
    })

  }



  if(storeConfiguration && vehicle){

    function StoreLogo(){
      if(storeSkin && storeSkin.logo_full){
        return(<img src={storeSkin.logo_full}/>)
      }
      // else if(vehicle.store && vehicle.store.logo_url){
      //   return(<img src={vehicle.store.logo_url}/>)
      // }
      return(
        <div>
          <IPacketIcon/>
        </div>
      )
    }

    return(
      <React.Fragment>
        <div className="marketplace-header-container vehicle-display-page-custom-header-container">
          <div className="marketplace-header-cta-container">
            <div className="marketplace-header-cta">
              {storeConfiguration.enable_zoom &&(
                <div className="marketplace-header-cta-item marketplace-header-cta-item-zoom"
                  onClick={() => {
                    pushModal({
                      type: MODAL_ACTIONS.MODAL_CONSTANTS.ASK_QUESTION,
                      props: {vehicle, id, title:"Zoom Meeting Request",etsGenericActionSubmitEvent:"zoom_request_submit"},
                    });
                    sendGenericActionEts("zoom_request_open")
                  }}
                >
                  <VideoChatIcon/>
                </div>
                )
              }

              <div
                onClick={
                  ()=>{
                    if(!printLoading){
                      sendGenericActionEts("print_open")
                      print()
                    }
                  }}
                className="marketplace-header-cta-item marketplace-header-cta-item-print"
              >
                {printLoading?<LoadingSpinner loading timeout={9999999999}/>:<PrintIcon/>}
              </div>
              <div className="aip-marketplace-weglot-container"></div>

            </div>
          </div>
          <div className="marketplace-header-nav-container">
            <div className="marketplace-header-nav">
              <div className="marketplace-header-nav-left">
                <StoreLogo/>
              </div>
              <div className="marketplace-header-nav-center">
              </div>
              <div className="marketplace-header-nav-right">
                {salespersonInfo?
                  storeConfiguration.store_follows_user?
                    <ContactInfo fullName={salespersonInfo.sales_person_name} phoneNumber={salespersonInfo.phone} contactImage={salespersonInfo.image} storeName={salespersonInfo.store_name}/>
                    :
                    <ContactInfo fullName={salespersonInfo.sales_person_name} phoneNumber={salespersonInfo.phone} contactImage={salespersonInfo.image} storeName={vehicle.store.full_name}/>
                  :
                  <ContactInfo fullName={vehicle.store.full_name} phoneNumber={vehicle.store.phone_number}/>
                }
                {/*<MobileNavDropdownToggle/>*/}
              </div>

            </div>
          </div>
          {
            mobileNavOpen?
            <div className="marketplace-header-mobile-nav-container">
              <div className="marketplace-header-mobile-nav-item"
                onClick={() => {
                  pushModal({
                    type: MODAL_ACTIONS.MODAL_CONSTANTS.ASK_QUESTION,
                    props: {vehicle, title:"Contact Dealer"},
                  });
                }}
              >
                Contact Dealer
              </div>
              <div className="marketplace-header-mobile-nav-item"
                onClick={() => {
                  pushModal({
                    type: MODAL_ACTIONS.MODAL_CONSTANTS.ASK_QUESTION,
                    props: {vehicle, title:"Zoom Meeting Request",etsGenericActionSubmitEvent:"zoom_request_submit"},
                  });
                  sendGenericActionEts("zoom_request_open")
                }}
              >
                Zoom Meeting Request
              </div>
              <a className="marketplace-header-mobile-nav-item" target="_blank" href={`https://www2.ipacket.info/print/${vehicle.vin}`}>Print</a>
            </div>
            :<React.Fragment/>
          }
        </div>

        <div className="marketplace-header-container-clear marketplace-header-container-clear-custom-vdp"/>
      </React.Fragment>
    )
  }
  return(<React.Fragment/>)
}




export default VehicleDisplayPageCustomHeader
