import React, {useEffect} from 'react'
import './notFoundPage.scss'

const NotFoundPage = (props)=>{
  let redirect = null
  useEffect(()=>{
    if(props.enableRedirect){
      redirect = setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    }
  },[])
  return(
    <div className="marketplace-page-not-found">
      {`Page Not Found${props.enableRedirect?' Redirecting...':''}`}
    </div>
  )
}
export default NotFoundPage
