import React from 'react'

const HealthIcon = props => {
  return (
    <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4129 15.438H15.8489V12.874C15.8489 12.4046 15.4878 12.0435 15.0183 12.0435H13.5377C13.0682 12.0435 12.7071 12.4046 12.7071 12.874V15.438H10.1431C9.67363 15.438 9.3125 15.7992 9.3125 16.2686V17.7492C9.3125 18.2187 9.67363 18.5798 10.1431 18.5798H12.7071V21.1438C12.7071 21.6133 13.0682 21.9744 13.5377 21.9744H14.9822C15.4516 21.9744 15.8128 21.6133 15.8128 21.1438V18.5798H18.3768C18.8462 18.5798 19.2073 18.2187 19.2073 17.7492V16.3047C19.2435 15.8353 18.8823 15.438 18.4129 15.438Z" fill="white"/>
        <path d="M28.452 9.3351C28.452 8.4684 28.4159 7.63781 28.4159 6.84334V6.01275C28.4159 5.47106 27.9825 5.03771 27.4408 5.03771H26.6102C21.9156 5.03771 18.4849 3.73765 15.4876 0.848646L14.9098 0.270844C14.5486 -0.0902815 13.9347 -0.0902815 13.5736 0.270844L12.9958 0.848646C10.0346 3.73765 6.60387 5.03771 1.87312 5.03771H1.04253C0.500844 5.03771 0.0674931 5.47106 0.0674931 6.01275V6.84334C0.0674931 7.63781 0.0674933 8.4684 0.0313807 9.3351C-0.11307 17.641 -0.329746 29.0526 13.6458 33.8917C14.0431 34.0361 14.4403 34.0361 14.8375 33.8917C28.8492 29.0526 28.5964 17.6771 28.452 9.3351ZM14.2597 25.2969C9.67344 25.2969 5.98996 21.5773 5.98996 17.0271C5.98996 12.4769 9.67344 8.7573 14.2597 8.7573C18.846 8.7573 22.5295 12.4408 22.5295 17.0271C22.5295 21.6134 18.846 25.2969 14.2597 25.2969Z" fill="white"/>
    </svg>
  )
}
export default HealthIcon