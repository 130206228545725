import React from 'react'

const EyeIcon = props => {
  return (
    <svg width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.5077 11.9334C36.1785 11.4461 28.3245 0 18.3329 0C8.3424 0 0.486912 11.4461 0.157293 11.9334C-0.052431 12.2436 -0.052431 12.6502 0.157293 12.9604C0.486912 13.4476 8.3424 24.893 18.3329 24.893C28.3241 24.893 36.1785 13.4476 36.5077 12.9604C36.7175 12.6498 36.7175 12.2436 36.5077 11.9334ZM18.3325 20.476C13.9049 20.476 10.3029 16.874 10.3029 12.4463C10.3029 8.01864 13.9052 4.41667 18.3325 4.41667C22.7602 4.41667 26.3622 8.01901 26.3622 12.4463C26.3625 16.874 22.7602 20.476 18.3325 20.476Z" fill="white"/>
        <path d="M18.3326 8.5415C16.1792 8.5415 14.4277 10.2934 14.4277 12.4463C14.4277 14.5997 16.1796 16.3512 18.3326 16.3512C20.4859 16.3512 22.2374 14.5997 22.2374 12.4463C22.2378 10.293 20.4859 8.5415 18.3326 8.5415Z" fill="white"/>
    </svg>
  )
}
export default EyeIcon