// npm packages
import React, {useState} from 'react'
import {connect} from 'react-redux'
import VideoPlayer from '../../../common/common-components/videoPlayer'
// scss file(s)
import './about-us.scss'
// images

import VideoPlaceholder from '../../../common/common-assets/images/ipacket-group-photo.jpeg'
import TransparencyPhoto from '../../../common/common-assets/images/Anthony-Dias-iPacket.jpg'
import SeveHeadshot from '../../../common/common-assets/images/headshot-seve-astorg.jpeg'
import KenHeadshot from '../../../common/common-assets/images/headshot-ken-wilkinson.jpeg'
import SeanHeadshot from '../../../common/common-assets/images/headshot-sean-ryan.jpeg'
import AmberHeadshot from '../../../common/common-assets/images/headshot-amber-starr.jpeg'
import TarynHeadshot from '../../../common/common-assets/images/headshot-taryn-young.jpeg'
import CameronHeadshot from '../../../common/common-assets/images/headshot-cameron-morris.jpeg'
import JasonHeadshot from '../../../common/common-assets/images/headshot-jason-good.jpg'
import SinaHeadshot from '../../../common/common-assets/images/headshot-sina-kagieni.jpeg'
import JeffHeadshot from '../../../common/common-assets/images/headshot-jeff-barr.jpeg'
import NadaTeamPicture from '../../../common/common-assets/images/nada_2024_ipacket_team_hi_res.jpg'
// icons
import PTOIcon from '../../../common/common-assets/icons/ptoIcon'
import HealthIcon from '../../../common/common-assets/icons/healthIcon'
import DentalIcon from '../../../common/common-assets/icons/dentalIcon'
import EyeIcon from '../../../common/common-assets/icons/eyeIcon'
import SavingsIcon from '../../../common/common-assets/icons/savingsIcon'
import MoneyIcon from '../../../common/common-assets/icons/moneyIcon'
import HeadsetIcon from '../../../common/common-assets/icons/headsetIcon'
import PresentationIcon from '../../../common/common-assets/icons/presentationIcon'
import VideoPlayerIcon from '../../../common/common-assets/icons/videoPlayerIcon'


const Introduction = () => {
    return (
        <div className="aboutus-page-introduction">
            <h1>AUTOIPACKET®</h1>
            <h2>ABOUT US</h2>
            <h3>We Replace Guesswork and stress with information and confidence</h3>
            <p>
                iPacket was crafted in a car dealership. We saw the angst every car buyer experienced.
                And we felt the burden every car dealer carried in trying to provide accurate information
                and backup. And we knew there was a better way. Today car buyers and dealers can simply
                turn to iPacket and find comprehensive detail, thorough documentation and everything they
                need to know about their cars right at their fingertips.
            </p>
        </div>
    )
}

const VitalStats = () => {
    return (
        <div className="aboutus-page-vitalstats">
            <span>
                <h1>2000+</h1>
                <p>Dealer Partners</p>
            </span>
            <span>
                <h1>10M+</h1>
                <p>Vehicles Sold</p>
            </span>
            <span>
                <h1>20M+</h1>
                <p>Shoppers Helped</p>
            </span>
            <span>
                <h1>100M+</h1>
                <p>Documents</p>
            </span>
        </div>
    )
}

const MainContent = () => {
    const [showVideo, setShowVideo] = useState(false)
    return (
        <div className="aboutus-page-main-content">
            <div className='main-content-row'>
                <div className='main-content-column'>
                    <h1>From the beautiful rolling hills of West Virginia to the world.</h1>
                    <p>
                        It might look like a quiet little village in the mountains, but Morgantown, WV
                        is home to one of the fastest growing technology companies in the car industry.
                        With West Virginia University right in our backyard we have access to some of the
                        most brilliant minds in the world. And we are revolutionizing the way cars are
                        bought and sold for millions of customers and dealers. We work hard. But we have fun.
                        Come join us!
                    </p>
                </div>
                <div className="main-content-column">
                    {
                        !showVideo ?
                        <div
                            className="image-placeholder video-player"
                            style={{backgroundImage:`url(${VideoPlaceholder})`, backgroundSize:`cover`, backgroundPosition:`center`}}
                            onClick={()=>setShowVideo(true)}
                        >
                          <div className="video-player-overlay">
                            <VideoPlayerIcon/>
                          </div>
                        </div>
                        :
                        <div className="image-placeholder video-player">
                          <iframe
                            ref={(r)=>{
                                try{
                                  r.play()
                                }
                                catch(e){
                                  //
                                }
                              }
                            }
                            src="https://player.vimeo.com/video/591042921?h=3a97f8665d&title=0&byline=0&portrait=0&autoplay=1"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                    }
                </div>
            </div>
            <div className='main-content-row'>
                <div className="main-content-column">
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${TransparencyPhoto})`, backgroundSize:`cover`, backgroundPosition:`center`}}
                    />
                </div>
                <div className='main-content-column'>
                    <h1>iPacket is a commitment to transparency by dealers you can trust.</h1>
                    <p>
                        When an auto retailer makes the commitment to integrate iPacket into their business, they’re sending you a powerful message. They’re telling you they want you to know everything and be an informed buyer. We take our mission seriously and so do our dealer partners. This is not just a belief in the power of transparency, but in the importance of integrity.
                    </p>
                </div>
            </div>
        </div>
    )
}

const TheLeadership = () => {
    return (
        <div className="aboutus-page-leadership">
            <h1>AUTOIPACKET®</h1>
            <h2>Leadership</h2>
            <h3>The founders, inventors & thinkers</h3>
            <div className="leadership-cards">
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${SeveHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Seve Astorg</p>
                        <p>CEO & Co-Founder</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${KenHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Ken Wilkinson</p>
                        <p>CTO & Co-Founder</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${SeanHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Sean Ryan</p>
                        <p>President - Business Development</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${AmberHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Amber Starr</p>
                        <p>VP of Partner Experience</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${CameronHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Cameron Morris</p>
                        <p>VP of Technology</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${JeffHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Jeff Barr</p>
                        <p>VP of Technical Services</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${SinaHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Sina Kagieni</p>
                        <p>VP of Operations</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${TarynHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Taryn Young</p>
                        <p>VP of Marketing</p>
                    </div>
                </span>
                <span>
                    <div
                        className="image-placeholder"
                        style={{backgroundImage:`url(${JasonHeadshot})`, backgroundSize:`cover`, backgroundPosition:`cover`}}
                    />
                    <div className="leadership-role">
                        <p className='name'>Jason Good</p>
                        <p>VP of Sales</p>
                    </div>
                </span>
            </div>
        </div>
    )
}

const IpacketCareers = () => {
    return(
        <div className="aboutus-page-careers">
            <h2 className="careers-header">Join Us</h2>
            <h1 className="careers-subheader">iPacket Careers</h1>
            <div className="benefit-cards">
                <span>
                    <div className="icon-container">
                        <PTOIcon />
                    </div>
                    <h1>Unlimited PTO</h1>
                    <p>
                        iPacket employees are encouraged to take time off throughout the year,
                        balancing personal and business needs. iPacket employees have MyTime, which
                        means there are no defined number of days for time off.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <HealthIcon />
                    </div>
                    <h1>Medical Insurance</h1>
                    <p>
                        We offer three medical plans through Highmark. All options include coverage for
                        preventive care, sick care or prescription drugs.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <DentalIcon />
                    </div>
                    <h1>Dental Insurance</h1>
                    <p>
                        We offer two services that provide coverage for preventative, basic and major services.
                        Both options are provided through Guardian Insurance.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <EyeIcon />
                    </div>
                    <h1>Vision Insurance</h1>
                    <p>
                        We are pleased to offer VSP vision insurance, one of the most comprehensive solutions for
                        eye care in the country with a very large network or participating providers.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <SavingsIcon />
                    </div>
                    <h1>HSA</h1>
                    <p>
                        Health Savings Account, under one of our qualifying health insurance plans, that we contribute
                        to, to offset any out-of-pocket medical expenses you might incur.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <MoneyIcon />
                    </div>
                    <h1>401(K)</h1>
                    <p>
                        Our 401(K) plan through Empower offers a company-match and numerous investment options to
                        choose from.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <HeadsetIcon />
                    </div>
                    <h1>Remote & on-site flex</h1>
                    <p>
                        iPacket offers both remote and on-site work environment options for our team. iPacket offices
                        are located in Parkersburg, WV.
                    </p>
                </span>
                <span>
                    <div className="icon-container">
                        <PresentationIcon />
                    </div>
                    <h1>Associate Training</h1>
                    <p>
                        iPacket provides access to leading-edge digital training opportunities through Pluralsight and
                        Masterclass to enrich your professional and personal development.
                    </p>
                </span>
            </div>
        </div>
    )
}

const TeamOpenings = () => {
    return(
        <div className="aboutus-page-team-openings">
            <h1>Team Openings</h1>
            <div className="team-openings-card">
                <div className='team-openings-contact'>
                    For more iPacket career information please contact <a href='mailto: careers@autoipacket.com'>careers@autoipacket.com</a>
                </div>
            </div>
        </div>
    )
}

function AboutUs(props) {
    return(
        <div className="aboutus-page">
            <div
                className="aboutus-header"
                style={{backgroundImage:`url(${NadaTeamPicture})`}}
            >
            </div>
            <Introduction />
            <VitalStats />
            <MainContent />
            <TheLeadership />
            <IpacketCareers />
            <TeamOpenings />

        </div>
    )
}
const mapStateToProps = state => ({})
export default connect(mapStateToProps)(AboutUs)
