import React, {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'
import './dealerCenterHeader.scss'
import IPacketIcon from '../../common/common-assets/icons/iPacketIcon'
import IPacketIconSimple from '../../common/common-assets/icons/iPacketSimpleIcon'
import MenuIcon from '../../common/common-assets/icons/menuIcon'
import CloseIcon from '../../common/common-assets/icons/closeicon'
import {NavLink} from 'react-router-dom'

function DealerCenterHeader(props){
  let [mobileNavOpen, setMobileNavOpen] = useState(false)
  // let [useSimpleIcon, setUseSimpleIcon] = useState(false)

  function toggleMobileNav(){
    setMobileNavOpen(!mobileNavOpen)
  }

  function closeMobileMenus(e) {
    if (refContainer && !refContainer.current.contains(e.target)) {
      setMobileNavOpen(false)
    }
  }

  const refContainer = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', closeMobileMenus, false)
    // window.innerWidth < 500 ? setUseSimpleIcon(true) : setUseSimpleIcon(false)
  }, [])

  let navLinks = [
    {
      label: "NEXT LEVEL TECH",
      to: '/#next_level_tech',
      className:'dealercenter-header-nextleveltech'
    },
    {
      label: "OEM WINDOW STICKERS",
      to: '/#oem_window_sticker',
      className:'dealercenter-header-oemwindowstickers'

    },,
    {
      label: "ABOUT US",
      to: "/about-us",
      className:'dealercenter-header-about-us'

    },
    {
      label: "SHOP CARS",
      to: "/#shop_cars",
      className:'dealercenter-header-about-us',
    }

  ]

  return(
    <React.Fragment>
      <div className="dealercenter-header-container" ref={refContainer}>
        <div className="dealercenter-header-nav-container">
          <div className="dealercenter-header-nav">
            <div className="dealercenter-header-nav-left">

                <NavLink to="/" className="ga-dealercenter-header-home-page-button" onClick={()=>{window.scrollTo(0, 0);}}>
                  <IPacketIcon/>
                </NavLink>
            </div>
            <div className="dealercenter-header-nav-center">
              {navLinks.map(navLink=>{
                return(
                  <a
                    href={navLink.to}
                    activeClassName="header-nav-link-active"
                    className={navLink.className}
                    target={navLink.target}
                  >
                    {navLink.label}
                  </a>
                )
              })}
            </div>
            <div className="dealercenter-header-nav-right">
              <div className="dealercenter-header-login-signup">
                <a className="primary-button" href="https://dpapp.autoipacket.com/login" target="_blank">DEALER LOGIN</a>
                <NavLink className="primary-button" to="/schedule-demo">BOOK A DEMO</NavLink>
              </div>
              <div
                className={`dealercenter-header-mobile-dropdown-toggle ${mobileNavOpen?'dealercenter-header-mobile-dropdown-toggle-selected':''}`}
                onClick={()=>{
                  toggleMobileNav()
                }}
              >
                {!mobileNavOpen ? <MenuIcon/> : <CloseIcon/>}
              </div>
              <div className="aip-marketplace-weglot-container"></div>
            </div>
          </div>
        </div>
        {
          mobileNavOpen?
          <div className="dealercenter-header-mobile-nav-container">
            {navLinks.map(navLink=>{
              return(
                <a
                  href={navLink.to}
                  className="dealercenter-header-mobile-nav-item"
                  activeClassName="header-nav-link-active"
                  onClick={()=>{
                    toggleMobileNav()
                  }}
                >
                  {navLink.label}
                </a>
              )
            })}
            <a className="dealercenter-header-mobile-nav-item" activeClassName="header-nav-link-active" href="https://dpapp.autoipacket.com/login" target="_blank">DEALER LOGIN</a>
          </div>
          :
          null
        }
      </div>
      <style>
        {`
          .intercom-lightweight-app, #intercom-container{
            display: block;
          }
        `}
      </style>
      <div className="dealercenter-header-container-clear"/>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps)(DealerCenterHeader)
